//import { Typography } from "@mui/material";
import React, { FC } from "react";
//import { useTranslation } from "react-i18next";
//import Lottie from "react-lottie";
//import drivingCar from "../../../../assets/illustrations/driving-car-animation.json";
import {
    CircularProgress,
} from "@mui/material";
const TechnicalAssistancePatienceDialog: FC<any> = (props: any) => {
  //const { t } = useTranslation();
  //const { isTechnical } = props;
  return (
      <CircularProgress style={{ color: "white" }} size={32} />
    /*<div style={{ height: props.height ?? "49vh" }}>
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignContent: "center",
          alignItems: "center",
        }}
      >
        <Lottie
          options={{
            animationData: drivingCar,
          }}
          height={250}
          isClickToPauseDisabled={true}
        />
      </div>
      <div
        style={{
          display: "flex",
          justifyContent: "space-around",
          flexDirection: "column",
          alignItems: "center",
          width: "100%",
        }}
      >
        {!isTechnical && (
          <Typography
            component={"div"}
            style={{
              width: "100%",
              fontWeight: "bold",
              textAlign: "center",
              fontSize: "16px",
            }}
            color="#E2E4E9"
          >
            {props?.title
              ? props?.title
              : window.location.pathname.includes("checkout")
              ? t("timeslotLoaderMessage")
              : t("positiveMessage")}
          </Typography>
        )}
      </div>
    </div>*/
  );
};
export default TechnicalAssistancePatienceDialog;
